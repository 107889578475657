<template>
    <v-app style="background: transparent" :class="{}">
        <template v-if="route_children">
            <router-view></router-view>
        </template>
        <template v-else>
            <!-- begin:: Content Head -->
            <KTSubheader
                    v-if="nav.subheaderDisplay"
                    v-bind:breadcrumbs="nav.breadcrumbs"
                    v-bind:title="nav.pageTitle"
            />
            <!-- end:: Content Head -->

            <div class="d-flex flex-column-fluid">
                <!--begin::Container-->
                <div class="container">
                    <div class="card condition" role="alert">
                        <div class="box-card">
                            <p>订单状态：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.status===''?'active':'']"
                                       @click="search_form.status=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in status_list"
                                    v-bind:key="item.value">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.status===item.value?'active':'']"
                                       @click="search_form.status=item.value"
                                    >{{item.text}}</a>
                                </li>

                            </ul>
                        </div>
                        <div class="box-card">
                            <p>平台渠道：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.plat_form==''?'active':'']"
                                       @click="search_form.plat_form=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in platform_list"
                                    v-bind:key="item.value">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.plat_form==item.value?'active':'']"
                                       @click="search_form.plat_form=item.value"
                                    >{{item.text}}</a>
                                </li>

                            </ul>
                        </div>
                        <div class="box-card">
                            <p>站点店铺：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.site_id==''?'active':'']"
                                       @click="search_form.site_id=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in site_list"
                                    v-bind:key="item.id">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.site_id==item.id?'active':'']"
                                       @click="search_form.site_id=item.id"
                                    >{{item.site_name}}</a>
                                </li>
                            </ul>
                        </div>

                        <div class="box-card">
                            <p>订单类型：</p>
                            <ul>
                                <li class="" style="">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.type==''?'active':'']"
                                       @click="search_form.type=''"
                                    >全部</a>
                                </li>
                                <li v-for="(item) in type_list"
                                    v-bind:key="item.id">
                                    <a href="javascript:void(0);"
                                       :class="[search_form.type==item.value?'active':'']"
                                       @click="search_form.type=item.value"
                                    >{{item.text}}</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <!--begin::Card-->
                    <div class="card card-custom">
                        <div class="card-header flex-wrap border-0 pt-6 pb-0">
                            <div class="card-title">
                                <h3 class="card-label">
                                    订单列表
                                    <span class="d-block text-muted pt-2 font-size-sm"></span>
                                </h3>
                            </div>
                            <div class="card-toolbar">

                                <!--begin::Dropdown-->
                                <div class="dropdown dropdown-inline mr-2">
                                    <button type="button"
                                            class="btn btn-light-primary font-weight-bolder dropdown-toggle"
                                            @click="syncMenuShow = !syncMenuShow"
                                    >
                                        订单同步
                                    </button>
                                    <div v-if="syncMenuShow" class="dropdown-menu dropdown-menu-sm dropdown-menu-right" style="display:block;">
                                        <ul class="navi flex-column navi-hover" style="padding-left:0;">
                                            <li class="navi-item"
                                                v-for="site in site_list"
                                                :key="site.id"
                                                @click="syncOrder(site.id)"
                                            >
                                                <a href="javascript:void(0);" class="navi-link">
                                                    <span class="navi-text">{{site.site_name}}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <a href="javascript:;" class="btn btn-primary font-weight-bolder mr-2" @click="exportExcel()">
                                    导出EXCEl
                                </a>
                                <!--begin::Button-->
                                <a href="javascript:;" class="btn btn-primary font-weight-bolder" @click="addOrder()">
                                    新增手工单
                                </a>
                                <!--end::Button-->
                            </div>
                        </div>
                        <div class="card-body">
                            <!--begin: Search Form-->

                            <!--begin::Search Form-->
                            <div class="mb-7">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 col-xl-12">
                                        <div class="row align-items-center">
                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">开始时间:</label>
                                                    <KTDatePicker
                                                            v-model="search_form.begin_time"
                                                    ></KTDatePicker>
                                                </div>
                                            </div>
                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">结束时间:</label>
                                                    <KTDatePicker
                                                            v-model="search_form.end_time"
                                                    ></KTDatePicker>
                                                </div>
                                            </div>

                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">产品名称:</label>
                                                    <input type="text" class="form-control" placeholder="产品标题" v-model="search_form.product_title">
                                                </div>
                                            </div>

                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">订单单号:</label>
                                                    <input type="text" class="form-control" placeholder="订单单号" v-model="search_form.order_number">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row align-items-center mt-5">
                                            <div class="col-md-3 my-2 my-md-0">
                                                <div class="d-flex align-items-center">
                                                    <label class="mr-3 mb-0 d-none d-md-block" style="width: 80px;">下单账号:</label>
                                                    <input type="text" class="form-control" placeholder="下单账号" v-model="search_form.user_account">
                                                </div>
                                            </div>
                                            <div class="col-md-3 my-2 my-md-0">
                                                <a href="javascript:void(0);" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--end::Search Form-->
                            <!--end: Search Form-->


                            <KTDatatable
                                    v-bind:list="list"
                                    v-bind:column="column"
                                    v-bind:showSelect="false"
                                    v-bind:showPagination="true"
                                    v-bind:pageSize="page.limit"
                                    v-bind:pageCount="page.pageCount"
                                    v-bind:total="page.total"
                                    @operateHandler="operateHandler"
                                    @pagechangeHandler="pagechangeHandler"
                            ></KTDatatable>

                        </div>
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Container-->
            </div>

            <v-dialog v-model="dialog_show" persistent :max-width="dialog_width">
                <component
                        :key="dialog_id"
                        :title="dialog_title"
                        :visible="dialog_show"
                        :is="dialog_view"
                        :dialog_data="dialog_data"
                        @opeareResultHandler="opeareResultHandler"></component>
            </v-dialog>
        </template>
    </v-app>

</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";
    import KTDatePicker from "@/view/content/date/DatePicker.vue";
    import KTUtil from "@/assets/js/components/util";
    import KTDialog from "@/assets/js/components/dialog";
    import showOrder from '@/view/pages/order/ShowOrder';
    import printExpresses from '@/view/pages/order/PrintExpresses';
    import sendOrder from '@/view/pages/order/SendOrder';
    import refundOrder from '@/view/pages/order/RefundOrder';
    import {
        ADD_BODY_CLASSNAME,
        REMOVE_BODY_CLASSNAME
    } from "@/core/services/store/htmlclass.module.js";
    import {getSiteListByPlatForm, getOrderList, updateOrderStatus, deleteOrderExpresses, getExportData, syncPlatOrder} from '@/api/order';
    import Swal from "sweetalert2";

    export default {
        name: "order_view",
        components: {
            KTSubheader,
            KTDatatable,
            KTDatePicker
        },
        created: function(){
            var _this = this;

            //判断路由
            if('/' + this.$route.name == this.$route.path){
                _this.searchList();
            } else {
                _this.route_children = true;
            }
            _this.getSiteList();
        },
        data() {
            return {
                route_children: false,
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/order', 'title':'订单管理'}],
                    pageTitle: '订单'
                },
                status_list: KTUtil.getOrderStatus(''),
                type_list: [
                    {'value': 1, 'text': '网站订单'},
                    {'value': 2, 'text': '手工订单'},
                ],
                platform_list: KTUtil.getPlatForm(),
                site_list: [],
                list: [],
                syncMenuShow: false,
                column: [{
                    field: 'order_number',
                    title: '订单编号',
                    width: 150,
                    formatter: function(row) {
                        var type = row.type==1?'网站订单':'手工订单';
                        return '<div><a class="show_order_detail" href="javascript:void(0);">' + row.order_number + '</a></div><div>'+ type +'</div>';
                    }
                },{
                    field: 'id',
                    title: '订单信息',
                    width: 300,
                    formatter: function(row) {
                        var html = '';
                        if(row.details) {
                            row.details.forEach(function(value) {
                                html += '<div class="d-flex align-items-center mb-5">';
                                html += '<div class="symbol symbol-60 flex-shrink-0">';
                                html += '<div class="symbol-label" style="background-image:url('+ value.image_url +')"></div>';
                                html += '</div>';
                                html += '<div class="ml-2">';
                                html += '<div class="text-dark-75 font-weight-bold line-height">'+ value.product_title +'</div>';
                                html += '<div class="font-size-sm text-dark-50 text-hover-primary">';
                                if(value.attr_info){
                                    var obj = JSON.parse(value.attr_info);
                                    for(var key in obj){
                                        html += '<span style="max-width:100px;" class="label label-primary label-inline font-weight-lighter mr-2" title="'+ key +' : '+ obj[key] +'">'+ obj[key] +'</span>';
                                    }
                                }
                                html += '<div class="text-dark-75 line-height">'+ value.sku +'</div>';
                                html += '<div class="text-dark-75 line-height"> quantity：'+ value.quantity +'</div>';
                                html += '<div class="text-dark-75 line-height"> price：'+ value.price +' USD</div>';
                                html += '</div>';
                                html += '</div>';
                                html += '</div>';
                            });
                        }
                        return html;
                    }
                },{
                    field: 'user_account',
                    title: '客户信息',
                    width: 200,
                    formatter: function(row) {
                        var html = '';
                        html += '<div class="d-flex align-items-center mb-5">';
                        html += '<div class="ml-2">';
                        html += '<div class="text-dark-75 font-weight-bold line-height">'+ row.user_account +'</div>';
                        html += '<div class="text-dark-75 line-height">支付信息：'+ row.pay_account +'</div>';
                        html += '<div class="text-dark-75 line-height">'+ row.channel +'</div>';
                        html += '</div>';
                        html += '</div>';
                        return html;
                    }
                },{
                    field: 'amount',
                    title: '订单金额',
                    formatter: function(row) {
                        return row.amount + ' ' + row.currency;
                    }
                },{
                    field: 'status',
                    title: '状态',
                    formatter: function(row) {
                        return KTUtil.getOrderStatus(row.status);
                    }
                },{
                    field: 'create_time',
                    title: '创建时间',
                    width: 100,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                },{
                    field: 'operate',
                    title: '操作',
                    width: 100,
                    formatter: function(row){
                        let html = '';

                        if(row.status == 0) {
                            html = '' +
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="编辑">'+
                                '<i class="la la-edit icon-lg"></i>'+
                                '</a>';
                        } else if(row.status == 1) {
                            html = '' +
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="编辑">'+
                                '<i class="la la-edit icon-lg"></i>'+
                                '</a>';

                            //手动单添加撤销功能
                            if(row.type == 2) {
                                html +=
                                    '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="撤销">'+
                                    '<i class="la la-times-circle icon-lg"></i>'+
                                    '</a>';
                            }

                            html +=
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="创建物流单">'+
                                '<i class="la la-paste icon-lg"></i>'+
                                '</a>';
                        } else if(row.status == 2) {
                            html +=
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="发货">'+
                                '<i class="la la-plane-departure icon-lg"></i>'+
                                '</a>';
                            html +=
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="删除物流单">'+
                                '<i class="la la-trash-o icon-lg"></i>'+
                                '</a>';
                            html +=
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="打印物流单">'+
                                '<i class="la la-print icon-lg"></i>'+
                                '</a>';
                        } else if(row.status == 3) {
                            html +=
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="收货">'+
                                '<i class="la la-plane-arrival icon-lg"></i>'+
                                '</a>';
                        }

                        if(row.amount != row.refund_amount && row.status < 5) {
                            html +=
                                '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="申请退款">' +
                                '<i class="la la-reply icon-lg"></i>' +
                                '</a>';
                        }


                        return html;

                    }
                }],
                search_form: {
                    product_title: '',
                    plat_form: '',
                    site_id: '',
                    status: '',
                    order_number: '',
                    user_account: '',
                    begin_time: '',
                    end_time: '',
                    type: ''
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null,
                dialog_width: '850px'
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if('/' + this.$route.name == this.$route.path){
                        this.route_children = false;
                    } else {
                        this.route_children = true;
                    }
                    this.searchList();
                },
                deep: true
            },
            'search_form.status':function (val){
                this.searchList();
            },
            'search_form.plat_form':function (val){
                this.getSiteList();
                this.searchList();

            },
            'search_form.site_id':function (val){
                this.searchList();
            },
            'search_form.type':function (val){
                this.searchList();
            }
        },
        methods: {
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data['page'] = _this.page.currPage;
                data['limit'] = _this.page.limit;
                getOrderList(data).then(function(res){
                    if(res){
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            operateHandler(clazz, row) {
                var _this = this;

                if(clazz.indexOf('la-edit') > -1){
                    this.$router.push({
                        path: '/order/updateorder',
                        query: {
                            'id': row.id
                        }
                    });
                } else if(clazz.indexOf('show_order_detail') > -1){

                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '订单详情';
                    this.dialog_width = '1200px';
                    this.dialog_view = showOrder;
                    this.dialog_data = {
                        form_data: {
                            'id': row.id
                        }
                    };
                } else if(clazz.indexOf('la-times-circle') > -1){

                    //删除操作
                    KTUtil.confirm('操作提示', '是否确认撤销该手工单？', function(){
                        updateOrderStatus({id: row.id, status: 5}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '撤销成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                } else if(clazz.indexOf('la-dolly') > -1){


                } else if(clazz.indexOf('la-paste') > -1){
                    this.$router.push({
                        path: '/order/expressesOrder',
                        query: {
                            'id': row.id
                        }
                    });
                } else if(clazz.indexOf('la-trash-o') > -1) {
                    KTUtil.confirm('操作提示', '是否确认删除该物流单？', function(){
                        deleteOrderExpresses({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                } else if(clazz.indexOf('la-print') > -1) {
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '打印运单';
                    this.dialog_view = printExpresses;
                    this.dialog_width = '1200px';
                    this.dialog_data = {
                        form_data: {
                            'id': row.id
                        }
                    };


                } else if(clazz.indexOf('la-plane-departure') > -1) {
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '订单发货';
                    this.dialog_view = sendOrder;
                    this.dialog_width = '1200px';
                    this.dialog_data = {
                        form_data: {
                            'order': row
                        }
                    };
                }  else if(clazz.indexOf('la-reply') > -1){
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '订单退款';
                    this.dialog_view = refundOrder;
                    this.dialog_width = '1200px';
                    this.dialog_data = {
                        form_data: {
                            'order': row
                        }
                    };
                } else if(clazz.indexOf('la-plane-arrival') > -1) {
                    //收货操作
                    KTUtil.confirm('操作提示', '是否确认收货并修改订单为已完成状态？', function(){
                        updateOrderStatus({id: row.id, status: 4}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '收货成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                }
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            addOrder() {
                this.$router.push({
                    path: '/order/addorder'
                });
            },
            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            },
            getSiteList() {
                var _this = this;
                getSiteListByPlatForm({'site_platform': _this.search_form.plat_form}).then(function(res) {
                    if(res && res.response){
                        _this.site_list = res.response;
                    }
                });
            },
            getOrderStatus(status) {
                return KTUtil.getOrderStatus(status);
            },
            exportExcel() {
                var _this = this;
                var head = ['订单编号', '订单创建时间', '订单状态', '所属平台', '所属站点店铺', '产品信息', '客户账号', '联系邮箱', '订单币种', '对应美元基础汇率', '订单总金额', '下单IP', '下单国家',
                    '订单类型', '手续费', '税', '运费', '折扣金额', '支付方式', '支付账号', '支付金额', '支付时间', '收款账号', '发货时间', '发货人', '退款金额', '收货地址', '订单备注'];
                var val = ['order_number', 'create_time', 'status', 'platform_id', 'site_name', 'product_info', 'user_account', 'contact_email', 'currency', 'origin_base', 'amount', 'ip', 'country',
                    'type', 'fee', 'tax', 'freight', 'coupon', 'channel', 'pay_account', 'pay_amount', 'pay_time', 'get_account', 'send_time', 'sender', 'refund_amount', 'delivery_address', 'note'];
                getExportData(this.search_form).then(function(res) {
                    if(res && res.response && res.response.length > 0) {
                        var list = res.response;

                        //数据处理
                        for(var i = 0;i < list.length;i++){
                            var value = list[i];
                            value['create_time'] = KTUtil.timestamp2date(value['create_time']);
                            value['pay_time'] = KTUtil.timestamp2date(value['pay_time']);
                            value['send_time'] = KTUtil.timestamp2date(value['send_time']);
                            value['status'] = KTUtil.getOrderStatus(value['status']);
                            value['platform_id'] = KTUtil.getPlatForm(value['platform_id']);

                            var html = '';
                            if(value.details) {
                                value.details.forEach(function(value) {
                                    html += value.product_title + '\r\n';
                                    html += value.sku + '\r\n';
                                    html += value.quantity + '\r\n';
                                    html += value.price + '\r\n';
                                });
                            }
                            value['product_info'] = html;
                            html = '';
                            var address = JSON.parse(value['delivery_address']);
                            if(address) {
                                html += '收件人：' + address.username + '\r\n';
                                html += '国家：' + _this.getCountryInfo(address.country_code) + '\r\n';
                                html += '州/省/郡：' + address.province + '\r\n';
                                html += '城市：' + address.city + '\r\n';
                                html += '邮编：' + address.postcode + '\r\n';
                                html += '联系电话：' + address.phone + '\r\n';
                                html += '详细地址：' + address.address + '\r\n';
                            }
                            value['delivery_address'] = html;

                        }

                        KTUtil.exportExcelFile(head, val, list, '订单列表');
                    } else {
                        KTUtil.toast(this, '结果提醒', '未查询到需要导出的订单数据', 'danger');
                    }
                });
            },
            getCountryInfo(code) {
                var html = ''
                var country_list = KTUtil.getCountryList();
                country_list.forEach(function(value) {
                    if(value.locale == code) {
                        html = value.en + '('+ value.zh +')';
                        return false;
                    }
                });
                return html;
            },
            syncOrder(site_id) {
                var _this = this;
                _this.syncMenuShow = false;
                _this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
                syncPlatOrder({'site_id': site_id}).then(function(res){
                    if(res && res.response) {
                        KTUtil.toast(_this, '结果提醒', res.response, 'success');
                    }
                    _this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                });

            }
        }
    }
</script>
