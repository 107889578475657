<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
        </div>
        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:600px;overflow:auto;">
                <div class="form-group row mb-2">
                    <label class="col-lg-4 col-form-label">订单编号:<span class="font-weight-bold ml-2">{{order.expresses_number}}</span></label>
                    <label class="col-lg-4 col-form-label">客户账号:<span class="font-weight-bold ml-2">{{order.user_account}}</span></label>
                    <label class="col-lg-4 col-form-label">订单状态:<span class="font-weight-bold ml-2">{{getOrderStatus(order.status)}}</span></label>
                </div>
                <div class="form-group row mb-2">
                    <label class="col-lg-4 col-form-label">订单金额:<span class="font-weight-bold ml-2">{{order.amount}} {{order.currency}}</span></label>
                    <label class="col-lg-4 col-form-label">已退金额:<span class="font-weight-bold ml-2">{{order.refund_amount?order.refund_amount:0}} {{order.currency}}</span></label>
                    <label class="col-lg-4 col-form-label">可退金额:<span class="font-weight-bold ml-2">{{towNumber(order.amount - order.refund_amount)}} {{order.currency}}</span></label>
                </div>
                <div class="separator separator-dashed my-5"></div>
                <div class="form-group row mb-5">
                    <label class="col-lg-1 col-form-label">退款金额:</label>
                    <div class="col-lg-3">
                        <input class="form-control tt-input"
                               type="text"
                               name="amount"
                               v-model="refund.amount">
                    </div>
                </div>

                <div class="form-group row mb-5">
                    <label class="col-lg-1 col-form-label">退款类型:</label>
                    <div class="col-lg-3">
                        <select class="form-control" v-model="refund.type" name="type">
                            <option value="我买错了">我买错了</option>
                            <option value="不想要了">不想要了</option>
                            <option value="其他原因">其他原因</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row mb-5">
                    <label class="col-lg-1 col-form-label">退款原因:</label>
                    <div class="col-lg-3">
                       <textarea class="form-control" v-model="refund.reason" name="reason"></textarea>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">退款</button>
                <button class="btn btn-secondary" type="button" @click="cancelForm()">关闭</button>
            </div>
        </form>
    </div>
</template>

<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import {refundOrder} from '@/api/order';

    export default {
        name: "send_order",
        components: {

        },
        props: {
            title: String,
            dialog_data: Object,
        },
        created: function(){
            var _this = this;


        },
        watch: {
            "refund.amount": function(val){
                var _this = this;
                val = parseFloat(val);
                val = val?val:0;
                if(val > (_this.order.amount - _this.order.refund_amount)) {
                    val = _this.order.amount - _this.order.refund_amount;
                }
                _this.refund.amount = val;
            }
        },
        data() {
            return {
                order: this.dialog_data.form_data.order,
                refund: {
                    order_id: this.dialog_data.form_data.order.id,
                    type: '',
                    amount: '',
                    reason: ''
                }
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    amount: {
                        validators: {
                            notEmpty: {
                                message: "请输入退款金额"
                            },
                            callback: {
                                message: "退款金额需大于0",
                                callback: function(value, validator) {
                                    if(value.value > 0) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            }
                        }
                    },
                    type: {
                        validators: {
                            notEmpty: {
                                message: "请选择退款类型"
                            },
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                refundOrder(_this.refund).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '退款成功', 'success');
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            opeareResultHandler(result) {
                var _this = this;
                if(result && result.length > 0){
                    result.forEach(function(value){
                        _this.order.details.push(value);
                    });

                }
                this.dialog.show = false;
            },
            getOrderStatus(status) {
                return KTUtil.getOrderStatus(status);
            },
            timestamp2date(time) {
                return KTUtil.timestamp2date(time);
            },
            towNumber(number) {
                return number.toFixed(2);
            },
            getCountryInfo(code) {
                var html = ''
                var country_list = KTUtil.getCountryList();
                country_list.forEach(function(value) {
                    if(value.locale == code) {
                        html = value.en + '('+ value.zh +')';
                        return false;
                    }
                });
                return html;
            },

        },

    }
</script>
