<template>
    <div class="card card-custom example example-compact">

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:600px;overflow:auto;">

                <b-tabs content-class="mt-3" >
                    <b-tab title="基本信息" active>
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">订单编号:<span class="font-weight-bold ml-2">{{order.order_number}}</span></label>
                            <label class="col-lg-4 col-form-label">客户账号:<span class="font-weight-bold ml-2">{{order.user_account}}</span></label>
                            <label class="col-lg-4 col-form-label">订单状态:<span class="font-weight-bold ml-2">{{getOrderStatus(order.status)}}</span></label>
                        </div>
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">付款方式:<span class="font-weight-bold ml-2">{{order.channel}}</span></label>
                            <label class="col-lg-4 col-form-label">付款账户:<span class="font-weight-bold ml-2">{{order.pay_account}}</span></label>
                            <label class="col-lg-4 col-form-label">付款时间:<span class="font-weight-bold ml-2">{{timestamp2date(order.pay_time)}}</span></label>
                        </div>
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">付款金额:<span class="font-weight-bold ml-2">{{order.pay_amount}} {{order.currency}}</span></label>
                            <label class="col-lg-4 col-form-label">收款账户:<span class="font-weight-bold ml-2">{{order.get_account}}</span></label>
                        </div>
                        <div class="separator separator-dashed my-5"></div>

                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">订单金额:<span class="font-weight-bold ml-2">{{order.total_amount}} {{order.currency}}</span></label>
                            <label class="col-lg-4 col-form-label">折扣金额:<span class="font-weight-bold ml-2">{{order.coupon}} {{order.currency}}</span></label>
                            <label class="col-lg-4 col-form-label">运费:<span class="font-weight-bold ml-2">{{order.freight}} {{order.currency}}</span></label>
                        </div>

                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">手续费:<span class="font-weight-bold ml-2">{{order.fee}} {{order.currency}}</span></label>
                            <label class="col-lg-4 col-form-label">税:<span class="font-weight-bold ml-2">{{order.tax}} {{order.currency}}</span></label>
                            <label class="col-lg-4 col-form-label">总金额:<span class="font-weight-bold ml-2">{{order.amount}} {{order.currency}}</span></label>
                        </div>
                        <div class="separator separator-dashed my-5"></div>
                        <div class="form-group row mb-2">
                            <label class="col-lg-2 col-form-label"><span class="font-weight-bold mr-2">订单备注:</span></label>
                            <div class="col-lg-12">
                                <textarea  class="form-control tt-input" name="note" v-model="order.note"></textarea>
                            </div>
                        </div>

                    </b-tab>
                    <b-tab title="产品信息">
                        <div class="form-group row" v-if="order.details.length > 0">
                            <table class="table table-vertical-center">
                                <thead>
                                <tr class="alert alert-light">
                                    <th scope="col">产品信息</th>
                                    <th scope="col" width="100px">价格(USD)</th>
                                    <th scope="col" width="100px">数量</th>
                                    <th scope="col" width="100px">金额({{order.currency}})</th>
                                </tr>
                                </thead>
                                <tbody>

                                <template v-for="(item, idx) in order.details">
                                    <tr :key="idx">
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="symbol symbol-60 flex-shrink-0">
                                                    <div class="symbol-label"
                                                         :style="'background-image:url('+item.image_url+')'"></div>
                                                </div>
                                                <div class="ml-2">
                                                    <div class="text-dark-75 font-weight-bold line-height">
                                                        {{item.product_title}}
                                                    </div>
                                                    <div class="text-dark-75 line-height">
                                                        {{item.sku}}
                                                    </div>
                                                    <div class="font-size-sm text-dark-50 text-hover-primary">
                                                        <template v-for="attr in JSON.parse(item.attr_info)">
                                                            <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td>{{item.price}}</td>
                                        <td>{{item.quantity}}</td>
                                        <td>{{towNumber(item.price*item.quantity/order.origin_base)}}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>

                    </b-tab>
                    <b-tab title="地址信息">
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">用户名:<span class="font-weight-bold ml-2">{{order.address.username}}</span></label>
                            <label class="col-lg-4 col-form-label">邮编:<span class="font-weight-bold ml-2">{{order.address.postcode}}</span></label>
                            <label class="col-lg-4 col-form-label">联系电话:<span class="font-weight-bold ml-2">{{order.address.phone}}</span></label>
                        </div>
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">国家:<span class="font-weight-bold ml-2">{{getCountryInfo(order.address.country_code)}}</span></label>
                            <label class="col-lg-4 col-form-label">州/省/郡:<span class="font-weight-bold ml-2">{{order.address.province}}</span></label>
                            <label class="col-lg-4 col-form-label">城市:<span class="font-weight-bold ml-2">{{order.address.city}}</span></label>
                        </div>
                        <div class="form-group row mb-2">
                            <label class="col-lg-12 col-form-label">详细地址:<span class="font-weight-bold ml-2">{{order.address.address}}</span></label>
                        </div>
                    </b-tab>
                    <b-tab title="物流信息" v-if="order.expresses">
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">发货渠道:<span class="font-weight-bold ml-2">{{order.expresses.ExpressType.ChannelType.Name}}</span></label>
                            <label class="col-lg-4 col-form-label">发货渠道(EN):<span class="font-weight-bold ml-2">{{order.expresses.ExpressType.ChannelType.NameEn}}</span></label>
                            <label class="col-lg-4 col-form-label">运单号:<span class="font-weight-bold ml-2">{{order.expresses.ExpressType.Epcode}}</span></label>
                        </div>

                        <div class="form-group row mb-2">
                            <label class="col-lg-12 col-form-label">产品名称:<span class="font-weight-bold ml-2">{{order.expresses.ExpressType.GoodsName.NameCh}}</span></label>
                        </div>
                        <div class="form-group row mb-2">
                            <label class="col-lg-12 col-form-label">产品名称(EN):<span class="font-weight-bold ml-2">{{order.expresses.ExpressType.GoodsName.NameEn}}</span></label>
                        </div>

                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">报关价格:<span class="font-weight-bold ml-2">{{order.expresses.ExpressType.GoodsName.DeclaredValue}} {{order.expresses.ExpressType.GoodsName.DeclaredCurrenc}}</span></label>
                            <label class="col-lg-4 col-form-label">报关重量(g):<span class="font-weight-bold ml-2">{{order.expresses.ExpressType.GoodsName.Weight}}</span></label>
                            <label class="col-lg-4 col-form-label">报关数量:<span class="font-weight-bold ml-2">{{order.expresses.ExpressType.Quantity}}</span></label>
                        </div>

                        <div class="separator separator-dashed my-5"></div>

                        <div class="form-group row mb-2">
                            <template v-for="(value, idx) in order.expresses.expresses_status">
                                <label :key="idx" class="col-lg-12 col-form-label">{{value.tracking_number}} | {{value.tracking_status}}</label>
                                <template v-for="(v, i) in value.checkpoints">
                                    <label :key="i" class="col-lg-12 col-form-label">
                                        - - - - {{v.time_stamp}} | {{v.tracking_status}} | {{v.message}}
                                    </label>
                                </template>
                            </template>
                        </div>
                    </b-tab>
                    <b-tab title="发货信息" v-if="order.send_info">
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">发货时间:<span class="font-weight-bold ml-2">{{timestamp2date(order.send_time)}}</span></label>
                            <label class="col-lg-4 col-form-label">操作人:<span class="font-weight-bold ml-2">{{order.sender}}</span></label>
                        </div>
                        <div class="separator separator-dashed my-5"></div>
                        <div class="form-group row mb-2">
                            <table class="table table-vertical-center">
                                <thead>
                                <tr class="alert alert-light">
                                    <th scope="col">产品信息</th>
                                    <th scope="col">物料编号</th>
                                    <th scope="col" width="100px">发货仓库</th>
                                    <th scope="col" width="100px">货架</th>
                                    <th scope="col" width="50px">数量</th>
                                    <th scope="col" width="100px">价格</th>
                                    <th scope="col" width="100px">库存价格</th>
                                    <th scope="col" width="100px">盈利</th>
                                </tr>
                                </thead>
                                <tbody>

                                <template v-for="(item, idx) in order.send_info">
                                    <tr :key="idx" v-if="item.summary == 4">
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="ml-2">
                                                    <div class="text-dark-75 font-weight-bold line-height">
                                                        {{item.product_title}}
                                                    </div>
                                                    <div class="text-dark-75 line-height">
                                                        {{item.sku}}
                                                    </div>
                                                    <div class="font-size-sm text-dark-50 text-hover-primary">
                                                        <template v-for="attr in JSON.parse(item.attr_info)">
                                                            <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{item.material_number}}</td>
                                        <td>{{item.warehouse_name}}</td>
                                        <td>{{item.shelf_number}}</td>
                                        <td>{{item.quantity}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{item.stock_price}}</td>
                                        <td>{{towNumber((item.price - item.stock_price) * item.quantity)}}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </b-tab>
                    <b-tab title="退款退货信息" v-if="order.refund_amount > 0">
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label">退款类型:<span class="font-weight-bold ml-2">{{order.channel}}</span></label>
                            <label class="col-lg-4 col-form-label">退款账号:<span class="font-weight-bold ml-2">{{order.pay_account}}</span></label>
                            <label class="col-lg-4 col-form-label">退款金额:<span class="font-weight-bold ml-2">{{order.refund_amount}} {{order.currency}}</span></label>
                        </div>
                        <div class="separator separator-dashed my-5"></div>
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label font-weight-bold ">退款记录</label>
                            <table class="table table-vertical-center">
                                <thead>
                                <tr class="alert alert-light">
                                    <th scope="col">退款时间</th>
                                    <th scope="col">退款金额</th>
                                    <th scope="col">退款类型</th>
                                    <th scope="col">退款原因</th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item, idx) in order.refund_info">
                                    <tr :key="idx">
                                        <td>{{timestamp2date(item.create_time)}}</td>
                                        <td>{{item.refund_amount}}</td>
                                        <td>{{item.type}}</td>
                                        <td>{{item.reason}}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="separator separator-dashed my-5"></div>
                        <div class="form-group row mb-2">
                            <label class="col-lg-4 col-form-label font-weight-bold ">退货记录</label>
                            <table class="table table-vertical-center">
                                <thead>
                                <tr class="alert alert-light">
                                    <th scope="col">产品信息</th>
                                    <th scope="col">物料编号</th>
                                    <th scope="col" width="100px">收货仓库</th>
                                    <th scope="col" width="100px">货架</th>
                                    <th scope="col" width="50px">数量</th>
                                    <th scope="col" width="100px">入库价格</th>
                                    <th scope="col" width="150px">入库时间</th>
                                </tr>
                                </thead>
                                <tbody>

                                <template v-for="(item, idx) in order.send_info">
                                    <tr :key="idx" v-if="item.summary == 3">
                                        <td>
                                            <div class="d-flex align-items-center">
                                                <div class="ml-2">
                                                    <div class="text-dark-75 font-weight-bold line-height">
                                                        {{item.product_title}}
                                                    </div>
                                                    <div class="text-dark-75 line-height">
                                                        {{item.sku}}
                                                    </div>
                                                    <div class="font-size-sm text-dark-50 text-hover-primary">
                                                        <template v-for="attr in JSON.parse(item.attr_info)">
                                                            <span :key="attr"
                                                                  class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{item.material_number}}</td>
                                        <td>{{item.warehouse_name}}</td>
                                        <td>{{item.shelf_number}}</td>
                                        <td>{{item.quantity}}</td>
                                        <td>{{item.price}}</td>
                                        <td>{{timestamp2date(item.create_time)}}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </b-tab>
                    <b-tab title="订单利润" v-if="order.status == 4 || order.status == 7">
                        <div class="form-group row mb-2" style="height:400px;">
                            <div class="col-lg-4" style="margin:20px auto;">
                                <label class="font-weight-bold" style="font-size:20px;vertical-align: super;">订单金额：</label>
                                <label><i class="la la-dollar" :class="[order.amount > 0?'text-primary':'text-danger']" style="font-size:40px;"> {{towNumber(order.amount / order.origin_base)}}</i></label>
                            </div>
                            <div class="col-lg-4" style="margin:20px auto;">
                                <label class="font-weight-bold" style="font-size:20px;vertical-align: super;">订单利润：</label>
                                <label><i class="la la-dollar" :class="[order.profit > 0?'text-primary':'text-danger']" style="font-size:40px;"> {{towNumber(order.profit)}}</i></label>
                            </div>
                            <div class="form-group row mb-2">
                                <template v-for="(p, idx) in order.profit_log">
                                    <label :key="idx" class="col-lg-12">{{p.msg}} :
                                        <span v-if="p.type == 1" class="font-weight-bold text-primary"> + $ {{towNumber(p.amount)}}</span>
                                        <span v-else class="font-weight-bold text-danger"> - $ {{towNumber(p.amount)}}</span>
                                    </label>
                                </template>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>

            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="button" @click="cancelForm()">关闭</button>
            </div>
        </form>
    </div>
</template>

<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import {getOrder, updateOrderNote} from '@/api/order';

    export default {
        name: "show_order",
        components: {

        },
        props: {
            title: String,
            dialog_data: Object,
        },
        created: function(){
            var _this = this;
            getOrder({'order_id': _this.dialog_data.form_data.id}).then(function(res) {
                if(res && res.response) {
                    _this.order = res.response;
                }
            });
        },
        watch: {

        },
        data() {
            return {
                order: {
                    id: '',
                    platform_id: '',
                    site_id: '',
                    product_id: null,
                    product_title: '',
                    details: [],
                    currency: 'USD',
                    origin_base: '',
                    total_amount: '',
                    fee: '',
                    freight: '',
                    coupon: '',
                    tax: '',
                    amount: '',
                    channel: '',
                    pay_account: '',
                    pay_amount: '',
                    get_account: '',
                    user_account: '',
                    ip: '',
                    country: '',
                    contact_email: '',
                    address: {
                        username: '',
                        country_code: null,
                        province: '',
                        city: '',
                        postcode: '',
                        phone: '',
                        address: ''
                    },
                    note: ''
                },

            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {

                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");
                var data = {
                    'note': _this.order.note,
                    'id': _this.order.id,
                };
                updateOrderNote(data).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        //data_form.reset();
                        //_this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            opeareResultHandler(result) {
                var _this = this;
                if(result && result.length > 0){
                    result.forEach(function(value){
                        _this.order.details.push(value);
                    });

                }
                this.dialog.show = false;
            },
            getOrderStatus(status) {
                return KTUtil.getOrderStatus(status);
            },
            timestamp2date(time) {
                return KTUtil.timestamp2date(time);
            },
            towNumber(number) {
                return number.toFixed(2);
            },
            getCountryInfo(code) {
                var html = ''
                var country_list = KTUtil.getCountryList();
                country_list.forEach(function(value) {
                    if(value.locale == code) {
                        html = value.en + '('+ value.zh +')';
                        return false;
                    }
                });
                return html;
            }

        },

    }
</script>
