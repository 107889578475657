<template>
    <div class="card card-custom example example-compact">
        <div class="card-body" style="min-height:600px;max-height:600px;overflow:auto;">
            <div>
                <b-tabs content-class="mt-3" v-model="type">
                    <b-tab title="A4L" active>
                        <iframe :src="url" id="IFRAME_A4L" style="width:100%;height:480px;"></iframe>
                    </b-tab>
                    <b-tab title="A6L">
                        <iframe :src="url" id="IFRAME_A4L" style="width:100%;height:480px;"></iframe>
                    </b-tab>
                    <b-tab title="A10x10L">
                        <iframe :src="url" id="IFRAME_A4L" style="width:100%;height:480px;"></iframe>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
        <div class="card-footer">
            <button class="btn btn-secondary" type="button" @click="cancelForm()">关闭</button>
        </div>
    </div>
</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import {expressesLabel} from '@/api/order';

    export default {
        name: "print_order_expresses",
        components: {

        },
        props: {
            title: String,
            dialog_data: Object,
        },
        created: function () {
            var _this = this;
            _this.getLabel();
        },
        watch: {
            'type': function(val) {
                this.getLabel();
            }
        },
        data() {
            return {
                type_list: [{'name': 'A4L'}, {'name': 'A6L'}, {'name': 'A10x10L'}],
                type: 0,
                url: ''
            }
        },
        methods: {
            cancelForm() {
                this.$emit('opeareResultHandler', 0);

            },
            getLabel() {
                var _this = this;
                var type =  _this.type_list[_this.type]['name'];
                var id = _this.dialog_data.form_data.id
                expressesLabel({'id':id , 'type': type}).then(function(res) {
                    if(res && res.response) {
                        var code = 'data:application/pdf;base64,' + res.response;
                        _this.url = code;
                    }
                });
            },

        }
    }
</script>
