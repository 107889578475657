<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>
        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:600px;overflow:auto;">
                <div class="form-group row mb-2">
                    <label class="col-lg-4 col-form-label">运单编号:<span class="font-weight-bold ml-2">{{order.expresses_number}}</span></label>
                    <label class="col-lg-4 col-form-label">客户账号:<span class="font-weight-bold ml-2">{{order.user_account}}</span></label>
                    <label class="col-lg-4 col-form-label">发货时间:<span class="font-weight-bold ml-2">{{timestamp2date(order.send_time)}}</span></label>
                </div>
                <div class="separator separator-dashed my-5"></div>
                <div class="form-group row mb-5">
                    <label class="col-lg-1 col-form-label">选择仓库:</label>
                    <div class="col-lg-3">
                        <b-form-select class="form-control" v-model="send_data.warehouse_id" :options="warehouse_list"
                                       name="warehouse_id">

                        </b-form-select>
                    </div>
                    <div class="col-lg-3" v-if="show_purchasement">
                        <a href="javascript:void(0);" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="addPurchasement()">一键采购</a>
                    </div>
                </div>
                <div class="form-group row mb-2">
                    <table class="table table-vertical-center">
                        <thead>
                        <tr class="alert alert-light">
                            <th scope="col">产品信息</th>
                            <th scope="col" width="160px">价格</th>
                            <th scope="col" width="100px">数量</th>
                            <th scope="col" width="100px">库存</th>
                            <th scope="col">库存明细</th>
                        </tr>
                        </thead>
                        <tbody>
                            <template v-for="(item, idx) in order.details">
                                <tr :key="idx">
                                    <td>
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-60 flex-shrink-0">
                                                <div class="symbol-label"
                                                     :style="'background-image:url('+item.image_url+')'"></div>
                                            </div>
                                            <div class="ml-2">
                                                <div class="text-dark-75 font-weight-bold line-height">
                                                    {{item.product_title}}
                                                </div>
                                                <div class="text-dark-75 line-height">
                                                    {{item.sku}}
                                                </div>
                                                <div class="font-size-sm text-dark-50 text-hover-primary">
                                                    <template v-for="attr in JSON.parse(item.attr_info)">
                                                        <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{item.price}}</td>
                                    <td>{{item.quantity}}</td>
                                    <td>{{item.stock?item.stock:0}}</td>
                                    <td>
                                        <template v-for="(stock, sidx) in item.stock_list">
                                            <div class="d-flex align-items-center" :key="sidx" v-if="stock.quantity > 0">
                                                <div class="symbol symbol-40 flex-shrink-0 mr-5">
                                                    <label class="checkbox checkbox-lg checkbox-single">
                                                        <input type="checkbox" v-model="order.details[idx].stock_list[sidx].checked" />
                                                        <span></span>
                                                    </label>
                                                </div>
                                                <div class="ml-2">
                                                    <div class="text-dark-75 font-weight-bold line-height">
                                                        {{stock.material_number}}
                                                    </div>
                                                    <div class="text-dark-75 line-height">
                                                        {{stock.warehouse_name}}
                                                    </div>
                                                    <div class="text-dark-75 line-height">
                                                        {{stock.shelf_number}}
                                                    </div>
                                                    <div class="text-dark-75 line-height">
                                                        单价：{{stock.price}} USD
                                                    </div>
                                                    <div class="text-dark-75 line-height">
                                                        库存：{{stock.quantity}}
                                                    </div>
                                                </div>
                                                <div class="ml-2 ml-5">
                                                    <input type="number" placeholder="请填写发货数量" class="form-control" v-model="order.details[idx].stock_list[sidx].send_quantity"/>
                                                </div>
                                            </div>
                                            <div :key="sidx + 'line'" class="separator separator-dashed my-5" v-if="sidx < item.stock_list.length - 1"></div>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">发货</button>
                <button class="btn btn-secondary" type="button" @click="cancelForm()">关闭</button>
            </div>
        </form>
    </div>
</template>

<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";
    import {getOrder, getWareHouse, getStockList, sendOrder} from '@/api/order';

    export default {
        name: "send_order",
        components: {

        },
        props: {
            title: String,
            dialog_data: Object,
        },
        created: function(){
            var _this = this;
            getWareHouse().then(function(res) {
                if(res && res.response){
                    _this.warehouse_list = KTUtil.listToSelectOptions(res.response, 'id', 'name');
                }
            });

            _this.order.details.forEach(function(val,idx) {
                getStockList({'product_id': val.product_id, 'sku_id': val.product_sku_id, 'quantity': '> 0'}).then(function(res) {
                    if(res && res.response && res.response.length > 0) {
                        _this.$set(_this.order.details[idx], 'stock_list', res.response);
                    } else {
                        _this.show_purchasement = true;
                    }
                });
            });

        },
        watch: {
            'send_data.warehouse_id': function(val) {
                var _this = this;
                if(_this.send_data.warehouse_id) {
                    _this.order.details.forEach(function(val,idx) {
                        getStockList({'warehouse_id': _this.send_data.warehouse_id, 'product_id': val.product_id, 'sku_id': val.product_sku_id}).then(function(res) {
                            if(res && res.response) {
                                _this.$set(_this.order.details[idx], 'stock_list', res.response);
                            } else {

                            }
                        });
                    });
                }
            },
            'order.details': {
                handler (val, oldval) {
                    var _this = this;
                    val.forEach(function(detail, idx) {

                        if(detail.stock_list && detail.stock_list.length > 0) {
                            detail.stock_list.forEach(function (stock, sidx) {
                                if(stock.send_quantity > 0) {
                                    _this.order.details[idx].stock_list[sidx].checked = true;
                                } else {
                                    _this.order.details[idx].stock_list[sidx].checked = false;
                                }
                            });
                        }
                    });
                },
                deep: true
            },
        },
        data() {
            return {
                order: this.dialog_data.form_data.order,
                warehouse_list: [],
                show_purchasement: false,
                send_data: {
                    warehouse_id: '',
                    selected: []
                }
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {

                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //检验发货数量
                var result = true;
                _this.order.details.forEach(function(detail, dinx) {

                    var count = 0;
                    detail.stock_list.forEach(function(stock, sindx) {

                        if(stock.checked && stock.send_quantity > 0) {
                            count += parseInt(stock.send_quantity);
                        }
                    });

                    if(count > detail.quantity) {
                        KTUtil.toast(_this, '错误提醒', '产品 ' + detail.product_title+ ' [SKU: ' + detail.sku + ']发货数量大于订单数量', 'danger');
                        result = false;
                    } else if(count < detail.quantity) {
                        KTUtil.toast(_this, '错误提醒', '产品 ' + detail.product_title+ ' [SKU: ' + detail.sku + ']发货数量小于订单数量', 'danger');
                        result = false;
                    }
                });
                if(!result) {
                    return;
                }

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                sendOrder(_this.order).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '发货成功', 'success');
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            opeareResultHandler(result) {
                var _this = this;
                if(result && result.length > 0){
                    result.forEach(function(value){
                        _this.order.details.push(value);
                    });

                }
                this.dialog.show = false;
            },
            getOrderStatus(status) {
                return KTUtil.getOrderStatus(status);
            },
            timestamp2date(time) {
                return KTUtil.timestamp2date(time);
            },
            towNumber(number) {
                return number.toFixed(2);
            },
            getCountryInfo(code) {
                var html = ''
                var country_list = KTUtil.getCountryList();
                country_list.forEach(function(value) {
                    if(value.locale == code) {
                        html = value.en + '('+ value.zh +')';
                        return false;
                    }
                });
                return html;
            },
            addPurchasement() {

                //判断哪些产品需要采购
                var product_ids = [];
                this.order.details.forEach(function(val, idx) {
                    if(val.stock_list && val.stock_list.length > 0) {

                    } else {
                        if(product_ids.indexOf(val.product_id) == -1) {
                            product_ids.push(val.product_id);
                        }
                    }
                });
                this.$router.push({
                    path: '/purchasement/rootpurchasement',
                    query: {
                        'order_id': this.order.id,
                        'warehouse_id': this.send_data.warehouse_id,
                        'product_ids': product_ids
                    }
                });
            }

        },

    }
</script>
